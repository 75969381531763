import React, { useEffect, useState, Suspense } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import styles from '../assets/css/style.module.css';
import TimeIcon from '@mui/icons-material/AccessTimeFilled';
import UserIcon from '@mui/icons-material/AccountCircle';
import Skeleton from '@mui/material/Skeleton';
import StarFilledIcon from '@mui/icons-material/StarRate';
import StarOutlineIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import ViewsIcon from '@mui/icons-material/Visibility';

import Image from '../../../components/ImageOptimization';

const RatingStars = ({ averageRating = 0, reviewCount = 0, index }) => {
  const fullStars = Math.floor(averageRating);
  const hasHalfStar = averageRating - fullStars >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
      <div className={styles.rating}>
          <ul key={index}>
              {Array(fullStars).fill(<StarFilledIcon className={styles.staricon} />)}
              {hasHalfStar && <StarHalfIcon className={styles.staricon} />}
              {Array(emptyStars).fill(<StarOutlineIcon className={styles.staricon} />)}
              <li>( {averageRating} out of <b>{reviewCount}</b> )</li>
          </ul>
      </div>
  );
};

const Slider = () => {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/sliders`)
      .then((response) => {
        setSliders(response.data);
      })
      .catch((error) => {
        console.error('Error fetching sliders:', error);
      });
  }, []);

if (!sliders) return <p>Loading...</p>;

  return (
    <Carousel className={styles.carousel}>
        {sliders.map((slider, index) => {
            const totalTime = slider.recipe.cooking_time + slider.recipe.preparation_time;
            const hours = Math.floor(totalTime / 60);
            const minutes = totalTime % 60;
            const formattedTime = hours > 0 ? `${hours} hr ${minutes} min` : `${minutes} min`;

            return (
                <Carousel.Item key={`slider${index}`} className={styles.item}>
                    <div style={{ width: '100%', height: '490px', overflow: 'hidden', position: 'relative' }}>
                        <Image
                        image={slider.desktop_image}
                        width="100%"
                        height="490"
                        loading="eager"
                        alt={slider.title}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                        </div>
                    <Carousel.Caption className={styles.caption}>
                        <h1 className="h1">
                            <Link to={`/recipe/${slider.recipe.permalink}`} className={styles.heading}>
                                {slider.recipe.title}
                            </Link>
                        </h1>
                        <p className="col-lg-8 col-md-10 col-sm-12 mx-auto px-2"
                         dangerouslySetInnerHTML={{ __html: slider.recipe.description }}>
                        </p>
                        <div className={styles.metaInfo}>
                            <ul>
                                <li>
                                    <Link to={`/category/${slider.recipe.category.permalink}`}>
                                        <UserIcon className={styles.icon} /> {slider.recipe.category.title}
                                    </Link>
                                </li>
                                <li><UserIcon className={styles.icon} /> By <b>{slider.recipe.user.full_name}</b></li>
                                <li><TimeIcon className={styles.icon} /> {formattedTime}</li>
                                <li><ViewsIcon className={styles.icon} /><b>{slider.view_count}</b> Views</li>
                            </ul>
                        </div>
                        
                        <RatingStars 
                            averageRating={slider.average_rating} 
                            reviewCount={slider.rating_count} 
                        />
                    </Carousel.Caption>
                </Carousel.Item>
            );
        })}
      </Carousel>
  );
};

export default Slider;
