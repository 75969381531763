import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SocialMedial_Block_V2 from './components/socialmedia_blockv2';
import NewsLetterBox from './components/newletter_box';
import Skeleton from 'react-loading-skeleton';

import Slider from './components/slider';
const CategoriesSidebar = React.lazy(() => import('./components/categories_sidebar'));
const SearchBox = React.lazy(() => import('./components/searchbox'));
const SearchFilter = React.lazy(() => import('./components/search_filter'));
const RecipeGridV1 = React.lazy(() => import('./components/grid/grid_v1'));
const RecipeGridV2 = React.lazy(() => import('./components/grid/grid_v2'));
const RecipeGridV3 = React.lazy(() => import('./components/grid/grid_v3'));



const Home = () => {

  return (
    <div>
      
       <div className='container-fluid p-0 m-0' style={{maxheight:490, background: '#dedede'}}>
         <Slider />
       </div>
      <div className='container' style={{minheight:158}}>
        <Suspense fallback={<Skeleton height={300} />}>
          <SearchFilter />
        </Suspense>
      </div>

      <div className='container' style={{minheight:238}}>
        <Suspense fallback={<Skeleton height={300} />}>
          <RecipeGridV1 />
        </Suspense>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-lg-9 col-md-8 col-sm-12'>
          <Suspense fallback={<Skeleton height={300} />}>
            <RecipeGridV2 />
          </Suspense>
          </div>
          <div className='col-lg-3 col-md-4 col-sm-12'>
            <Suspense fallback={<Skeleton height={500} />}>
              <SearchBox />
            </Suspense>
            <Suspense fallback={<Skeleton height={300} />}>
              <CategoriesSidebar />
            </Suspense>
            <Suspense fallback={<Skeleton height={300} />}>
              <SocialMedial_Block_V2 />
            </Suspense>
            <Suspense fallback={<Skeleton height={300} />}>
              <NewsLetterBox />
            </Suspense>
          </div>
        </div>
      </div>

        <div>
        <Suspense fallback={<Skeleton height={300} />}>
           <RecipeGridV3 />
        </Suspense>
        </div>
    </div>
  );
};

export default Home;
