import React, { useEffect, useState, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider and createTheme
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Helmet } from 'react-helmet';

// Define a theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize your primary color here
    },
    secondary: {
      main: '#f50057', // Customize your secondary color here
    },
    background: {
      default: '#f4f6f8', // Optional background color
    },
  },
});

const Root = () => {
  const [settings, setSettings] = useState(null);

  // Fetch settings
  const fetchSettings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/backend/api/setting`);
      const data = await response.json();
      if (data.success) {
        setSettings(data.data);
      } else {
        console.error('Failed to fetch settings:', data.message);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  // Conditional rendering: Show loading spinner until settings are fetched
  if (!settings) {
    return <div>Loading...</div>; // Replace this with your preferred loading spinner or message
  }

  return (
    <>
      <Helmet>
        <title>{settings.site_title || 'Default Title'}</title>
        <meta name="description" content={settings.description || 'Default description'} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={settings.meta_keyword || ''} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>Loading application...</div>}>
          <App />
        </Suspense>
      </ThemeProvider>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>
  </React.StrictMode>
);

// Measure performance in your app
reportWebVitals();
serviceWorkerRegistration.register();
